/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
	      var navCollapsed = false;
	      function navHandler(){
		      var screenWidth = $( window ).width();
		      if(screenWidth > 767 && screenWidth <= 991){
			      if ($(".navbar").offset().top > 0) {
			     // if ($(".navbar").offset().top > 150) {
				      $( '.under-top-nav' ).animate({ /// white bar
					      height: 102 //120
				      }, 500, function() {
					      //
				      });
				      $( '.under-top-nav-black' ).animate({ /// white bar
					      top: 101
				      }, 500, function() {
					      //
				      });
				      $( '.nav-holder' ).animate({ //// holds logo and nav
					      top: 45 //20
				      }, 500, function() {
					      //
				      });
				      //$( '.color-logo' ).css("opacity", "1");
				      $( '.color-logo' ).animate({ //// holds logo and nav
					      width: 200,
					      opacity: 1,
					      height: 50
				      }, 500, function() {
					      //
				      });
				      $( '.white-logo' ).animate({ //// holds logo and nav
					      opacity: 0,
					      width: 200,
					      height: 50
				      }, 500, function() {
					     // $( '.white-logo' ).css('display','none');
				      });
				      /////
				      $( '.logo-flag-holder' ).animate({ //// holds logo and nav
					      width: 310, //280,
					      left: -10,
					      height: 58
				      }, 500, function() {
					      //
				      });
				      $(  '.navbar-brand' ).animate({ //// holds logo and nav
					      width: 200,
					      height: 55
				      }, 500, function() {
					      //
				      });
				      ///
				      $( '.job-flag' ).animate({
					       top: 10,
					       width: 95,
					       height: 45
					      // left: 0, //-100
					      // width: 65,
					      // height: 45
				      }, 500, function(){

				      });
				      $( '.job-flag' ).addClass('jf-blue jf-small');
				      $( '.job-flag' ).removeClass('shadow');
				      $( '.navbar-nav>li>a' ).addClass("collapsed");

				      if(!navCollapsed){
					      $( '.menu-mobile-menu-container' ).addClass("pad-top");
					      $( '.menu-mobile-menu-container' ).css("opacity", "0");
					      $( '.menu-mobile-menu-container' ).animate({
						      opacity: 1
					      }, 750, function(){
						      //
					      });
				      }

				       navCollapsed = true;
			      } else {
				      $( '.under-top-nav' ).animate({ /// white bar
					      height: 47
				      }, 500, function() {
					      //
				      });
				      $( '.under-top-nav-black' ).animate({ /// white bar
					      top: -25
				      }, 500, function() {
					      //
				      });
				      $( '.nav-holder' ).animate({ //// holds logo and nav
					      top: 65
				      }, 500, function() {
					      //
				      });

				      //$( '.white-logo' ).css('display','block');
				      $( '.white-logo' ).animate({ //// holds logo and nav
					      opacity: 1,
					      width: 300,
					      height: 64
				      }, 500, function() {
					      //
				      });
				      $( '.color-logo' ).animate({ //// holds logo and nav
					      width: 300,
					      height: 64,
					      opacity: 0
				      }, 500, function() {
					      $( '.color-logo' ).css("opacity", "0");
				      });
				      /////
				      $( '.logo-flag-holder' ).css("overflow", "visible");
				      $( '.logo-flag-holder' ).animate({ //// holds logo and nav
					      width: 450, //415,
					      height: 71,
					      left: 0
				      }, 500, function() {
					      //
				      });
				      $(  '.navbar-brand' ).animate({ //// holds logo and nav
					      width: 330,
					      height: 69
				      }, 500, function() {
					      //
				      });
				      ///
				      $( '.job-flag' ).animate({
					      width: 130, //90,
					      height: 30,
					      top: 5 //20
				      }, 500, function(){
					      $( '.job-flag' ).addClass('shadow');
				      });
				      $( '.job-flag' ).removeClass('jf-blue jf-small');

				      $( '.navbar-nav>li>a' ).removeClass("collapsed");

				      if(navCollapsed) {
					      $( '.menu-mobile-menu-container' ).removeClass("pad-top");
					      $('.menu-mobile-menu-container').css("opacity", "0");
					      $('.menu-mobile-menu-container').animate({
						      opacity: 1
					      }, 750, function () {
						      //
					      });
				      }
				      navCollapsed = false;
			      }

		      }
		     else if(screenWidth > 991 && screenWidth <= 1199 ) {
			      //if ($(".navbar").offset().top > 150) {
			      if ($(".navbar").offset().top > 0) {
				      $( '.under-top-nav' ).animate({ /// white bar
					      height: 102 //120
				      }, 500, function() {
					      //
				      });
				      $( '.under-top-nav-black' ).animate({ /// white bar
					      top: 101
				      }, 500, function() {
					      //
				      });
				      $( '.nav-holder' ).animate({ //// holds logo and nav
					      top: 45 //20
				      }, 500, function() {
					      //
				      });
				      //$( '.color-logo' ).css("opacity", "1");
				      $( '.color-logo' ).animate({ //// holds logo and nav
					      width: 200,
					      opacity: 1,
					      height: 50
				      }, 500, function() {
					      //
				      });
				      $( '.white-logo' ).animate({ //// holds logo and nav
					      opacity: 0,
					      width: 200,
					      height: 50
				      }, 500, function() {
					      //$( '.white-logo' ).css('display','none');
				      });
				      /////
				      $( '.logo-flag-holder' ).animate({ //// holds logo and nav
					      width: 320, //280,
					      left: -10,
					      height: 58
				      }, 500, function() {
					      //
				      });
				      $(  '.navbar-brand' ).animate({ //// holds logo and nav
					      width: 200,
					      height: 55
				      }, 500, function() {
					      //
				      });
				      ///
				      $( '.job-flag' ).animate({
					      top: 10,
					      // left: 0, //-100
					      width: 100, //130, //65,
					      height: 30 //45
				      }, 500, function(){
					      //
				      });
				      $( '.job-flag' ).addClass('jf-blue jf-small');
				      $( '.job-flag' ).removeClass('shadow');
				      $( '.navbar-nav>li>a' ).addClass("collapsed");

				      if(!navCollapsed){
					      $( '.menu-mobile-menu-container' ).addClass("pad-top");
					      $( '.menu-mobile-menu-container' ).css("opacity", "0");
					      $( '.menu-mobile-menu-container' ).animate({
						      opacity: 1
					      }, 750, function(){
						      //
					      });
				      }

				      navCollapsed = true;
			      } else {
				      $( '.under-top-nav' ).animate({ /// white bar
					      height: 47
				      }, 500, function() {
					      //
				      });
				      $( '.under-top-nav-black' ).animate({ /// white bar
					      top: -25
				      }, 500, function() {
					      //
				      });
				      $( '.nav-holder' ).animate({ //// holds logo and nav
					      top: 65
				      }, 500, function() {
					      //
				      });
				      //$( '.white-logo' ).css('display','block');
				      $( '.white-logo' ).animate({ //// holds logo and nav
					      opacity: 1,
					      width: 300,
					      height: 64
				      }, 500, function() {
					      //
				      });
				      $( '.color-logo' ).animate({ //// holds logo and nav
					      width: 300,
					      height: 64,
					      opacity: 0
				      }, 500, function() {
					      $( '.color-logo' ).css("opacity", "0");
				      });
				      /////
				      $( '.logo-flag-holder' ).css("overflow", "visible");
				      $( '.logo-flag-holder' ).animate({ //// holds logo and nav
					      width: 450, //415,
					      height: 71,
					      left: 0
				      }, 500, function() {
					      //
				      });
				      $(  '.navbar-brand' ).animate({ //// holds logo and nav
					      width: 330,
					      height: 69
				      }, 500, function() {
					      //
				      });
				      ///
				      $( '.job-flag' ).animate({
					      width: 130, //90,
					      height: 30,
					      top: 5 //20
				      }, 500, function(){
					      $( '.job-flag' ).addClass('shadow');
				      });
				      $( '.job-flag' ).removeClass('jf-blue jf-small');

				      $( '.navbar-nav>li>a' ).removeClass("collapsed");

				      if(navCollapsed) {
					      $( '.menu-mobile-menu-container' ).removeClass("pad-top");
					      $('.menu-mobile-menu-container').css("opacity", "0");
					      $('.menu-mobile-menu-container').animate({
						      opacity: 1
					      }, 750, function () {
						      //
					      });
				      }
				      navCollapsed = false;
			      }
		      }
		      else if(screenWidth > 1199) {
			      //if ($(".navbar").offset().top > 150) {
			      if ($(".navbar").offset().top > 0) {
				      $( '.under-top-nav' ).animate({ /// white bar
					      height: 106 //120
				      }, 500, function() {
					      //
				      });
				      $( '.under-top-nav-black' ).animate({ /// white bar
					      top: 105 //119
				      }, 500, function() {
					      //
				      });
				      $( '.nav-holder' ).animate({ //// holds logo and nav
					      top: 20
				      }, 500, function() {
					      //
				      });

				      $( '.logo-flag-holder' ).animate({ //// holds logo and nav
					     top: -3,
					      width: 350
				      }, 500, function() {
					      //
				      });

				      $( '.color-logo' ).animate({ //// holds logo and nav
					      width: 230,
					      height: 64,
					      opacity: 1
				      }, 500, function() {
					      //
				      });
				      $( '.white-logo' ).animate({ //// holds logo and nav
					      opacity: 0,
					      width: 230,
					      height: 64
				      }, 500, function() {
					     // $( '.white-logo' ).css('visibility','hidden');
				      });
				      $( '.job-flag' ).animate({
					      top: 12,
					      //right: 70,
					      width: 100, //130, //65,
					      height: 30 //45
				      }, 500, function(){
					      //
				      });
				      $( '.job-flag' ).addClass('jf-blue jf-small');
				      $( '.job-flag' ).removeClass('shadow');
				      if(!navCollapsed){
				      	  $( '.menu-mobile-menu-container' ).addClass("pad-top");
					      $( '.navbar-nav>li>a' ).addClass("collapsed");
					      //$( '.menu-primary-nav-container' ).addClass("collapsed");
					      $( '.menu-mobile-menu-container' ).css("opacity", "0");
					      $( '.menu-mobile-menu-container' ).animate({
						      opacity: 1
					      }, 750, function(){
						      //
					      });
				      }

				      navCollapsed = true;
			      } else {
				      $( '.under-top-nav' ).animate({ /// white bar
					      height: 47
				      }, 500, function() {
					      //
				      });
				      $( '.under-top-nav-black' ).animate({ /// white bar
					      top: -25
				      }, 500, function() {
					      //
				      });
				      $( '.nav-holder' ).animate({ //// holds logo and nav
					      top: 65
				      }, 500, function() {
					      //
				      });

				      $( '.logo-flag-holder' ).animate({ //// holds logo and nav
					      top: 0,
					      width: 450
				      }, 500, function() {
					      //
				      });
				      //$( '.white-logo' ).css('visibility','visible');
				      $( '.white-logo' ).animate({ //// holds logo and nav
					      opacity: 1,
					      width: 300,
					      height: 64
				      }, 500, function() {
					      //$( '.white-logo' ).css('visibility','visible');
				      });
				      $( '.color-logo' ).animate({ //// holds logo and nav
					      width: 300,
					      height: 64,
					      opacity: 0
				      }, 500, function() {
					      $( '.color-logo' ).css("opacity", "0");
				      });
				      $( '.job-flag' ).animate({
					      top: 5, //20,
					      width: 130,
					      height: 30
					      //left: 0
				      }, 500, function(){
					      $( '.job-flag' ).addClass('shadow');
				      });
				      $( '.job-flag' ).removeClass('jf-blue jf-small');
				      $( '.navbar-nav>li>a' ).removeClass("collapsed");
				      $( '.menu-primary-nav-container' ).removeClass("collapsed");

				      if(navCollapsed) {
					      $( '.menu-mobile-menu-container' ).removeClass("pad-top");
					      $('.menu-mobile-menu-container').css("opacity", "0");
					      $('.menu-mobile-menu-container').animate({
						      opacity: 1
					      }, 750, function () {
						     //
					      });
				      }
				      navCollapsed = false;

			      }
		      }

	      }
	      var screenWidth = $( window ).width();

	        if(screenWidth > 767) {
		        if($('.job-flag').length >0 ){
			        $('.job-flag').delay( 1000 ).animate({
				        opacity: 1,
				        top: 5
			        }, 500, function() {
				        //
			        });
		        }
	        }


		//// equal heights function
	      var equalheight = function (container) {

		      var currentTallest = 0,
				      currentRowStart = 0,
				      rowDivs = [],
				      $el,
				      $currentDiv,
				      topPosition = 0;
		      $(container).each(function () {

			      $el = $(this);
			      $($el).height('auto');
			      topPostion = $el.position().top;

			      if (currentRowStart !== topPostion) {
				      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
					      rowDivs[currentDiv].height(currentTallest);
				      }
				      rowDivs.length = 0; // empty the array
				      currentRowStart = topPostion;
				      currentTallest = $el.height();
				      rowDivs.push($el);
			      } else {
				      rowDivs.push($el);
				      currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
			      }
			      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
				      rowDivs[currentDiv].height(currentTallest);
			      }
			      ///// racing stripes and content were paired up for equalheights but have different paddings
			      ///// this became apparent when the gradient bottom was changed from white to gray
			      ///// the hard-coded value will clear and make the racing stripe full height
			      // if($('div').hasClass( "stripe-holder" )){
				   //    var actualHeight = $('.stripe-holder').height();
				   //    var newHeight = actualHeight + 70;
				   //    $('.stripe-holder').height(newHeight);
			      // }
			      // if($('div').hasClass( "stripe-holder-cs" )){
				   //    var actualHeight2 = $('.stripe-holder-cs').height();
				   //    var newHeight2 = actualHeight2 + 120;
				   //    $('.stripe-holder-cs').height(newHeight2);
			      // }
		      });

	      };

		function setServices(){
			if(screenWidth > 480) {
				var totalHeight = $('.check-this').outerHeight();
				var halfHeight = totalHeight / 2;
				$('.service-holder').css("height", halfHeight + "px");
			}
		}
	      setServices();
	      function equalizeHeights(){
		      if($('div').hasClass( "home-services-card" )){
			      equalheight('.home-services-card');
		      }
		      // if($('div').hasClass( "stripe-match" )){
			   //    equalheight('.stripe-match');
		      // }
		      if($('div').hasClass( "services-set" )){
			      equalheight('.services-set');
		      }
	      }
	      equalizeHeights();

	      ////// SCROLL HANDLING
	      var readyToCheck = true;
	     //// requires scrollstop.js plugin
	      jQuery( document ).on("scrollstart", function( ) {
		      if(readyToCheck){
		      	 navHandler();
			     readyToCheck = false;
		      }
		  });
	      jQuery( document ).on("scrollstop", function() {
		      if(!readyToCheck){
			      navHandler();
			      readyToCheck = true;
		      }
	      });
	      //// end scrollstop

	      //// added to close the hamburger menu on click
	      $('.navbar-nav').find('a').on('click', function(e){
		      $('.navbar-toggle').trigger('click');
	      });

	      $('.navbar-toggle').on('click', function(e){
		      $('.navbar-toggle').toggleClass('open closed');//
	      });

	      $( window ).resize(function() {
		      clearTimeout(jQuery.data(this, 'resizeTimer'));
		      $.data(this, 'resizeTimer', setTimeout(function() {
			      equalizeHeights();
			      navHandler();
		      }, 200));
	      });


	      $('.charity-carousel').slick({
		      centerMode: true,
		      centerPadding: '15px',
		      slidesToShow: 4,
		      slidesToScroll: 4,
		      speed: 500,
		      autoplay: true,
		      autoplaySpeed: 3000,
		      variableWidth: false,
		      responsive: [
			      {
				      breakpoint: 769,
				      settings: {
					      arrows: true,
					      centerPadding: '10px',
					      slidesToShow: 3,
					      slidesToScroll: 3
				      }
			      },
			      {
				      breakpoint: 480,
				      settings: {
					      //centerMode: true,
					      arrows: false,
					      centerPadding: '20px',
					      slidesToShow: 1,
					      slidesToScroll: 1,
					      infinite: true
				      }
			      }
		      ]
	      });///// end charity-carousel

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
	      var screenWidth = $( window ).width();
	      if(screenWidth < 768){
		      $('.partners-carousel').slick({
			      arrows: false,
			      centerMode: true,
			      infinite: true,
			      centerPadding: '10px',
			      slidesToShow: 3,
			      speed: 500,
			      autoplay: true,
			      autoplaySpeed: 3000,
			      variableWidth: true

		      });///// end partners-carousel
	      }

	      ///// show the background video
	      var vid = document.getElementById("pVideo");
	      function showVideoHero(){
		      $( ".module-video-hero" ).animate({
			      opacity: 1
		      }, 800, function () {
			      //
		      });
	      }
	      var pVideoCheck = '';
	      function checkVideo(){
		      if ( vid.readyState === 4 ) {
			      showVideoHero();
			      clearInterval(pVideoCheck);
		      }
	      }
	      if(vid){
		      pVideoCheck = setInterval(checkVideo, 250);
	      }else{
		      showVideoHero();
	      }


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page

      }
    },
	  'careers':{
    	init: function(){
			function toggleIcon(e) {
					$(e.target)
						 .prev('.panel-heading').toggleClass('active-q active-no');
					//console.log('ok' + $(e.target).attr('id'));
			 }
			 $('.panel-group').on('hidden.bs.collapse', toggleIcon);
			 $('.panel-group').on('shown.bs.collapse', toggleIcon);

		    $('#accordion2').on('show.bs.collapse', function (e) {
			    $('#accordion .in').collapse('hide');
		    });
		    $('#accordion').on('show.bs.collapse', function (e) {
			    $('#accordion .in').collapse('hide');
		    });

		    function fileUpload(){

			    // file upload
			    $("li.upload :input").change(function () {

				    var label = this.value;
				    var id = $(this).attr("id");
				    var target = $("label[for=" + id + "]");
				    var truncate = function (fullStr, strLen, seperator) {

					    // truncate file path from file name, escape \ char as \\
					    var fileNameIndex = fullStr.lastIndexOf("\\") + 1;
					    var filename = fullStr.substr(fileNameIndex);

					    if (filename.length <= strLen) {
						    return filename;
					    }

					    seperator = seperator || '... ';

					    var sepLen = seperator.length,
							    charsToShow = strLen - sepLen,
							    frontChars = Math.ceil(charsToShow - 4),
							    backChars = Math.floor(4);

					    return filename.substr(0, frontChars) +
							    seperator +
							    filename.substr(filename.length - backChars);
				    };

				    $(target).html(truncate(label, 20));

				    $(this).parent(target).addClass("success");
			    });
		    }

			fileUpload();


	    }
	  },
	  'contact_us':{
	  	init: function(){
		    ///// dynamically resize map
		    function resizeMap(){
			    //var fullWidth = $( '.map-holder' ).width();
			    var fullHeight = $( '.map-holder' ).height();
			    //$("iframe").css({"width":fullWidth, "height": fullHeight});
			    $("iframe").css({"height": fullHeight});
		    }
		    setTimeout(function(){ resizeMap(); }, 1500);
		    $( window ).resize(function() {
			    clearTimeout(jQuery.data(this, 'resizeTimer'));
			    $.data(this, 'resizeTimer', setTimeout(function() {
				    resizeMap();
			    }, 200));
		    });
	    }
	  }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
